<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.location"
              :options="codes.bannerLocation"
              class="el-def"
              placeholder="위치"
              :reduce="option => option.code"
            />
            <v-select
              v-model="query.contentType"
              :options="codes.bannerContentType"
              class="el-def"
              placeholder="구분"
              :reduce="option => option.code"
            />
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
            <v-select
              v-model="query.postStatus"
              :options="codes.postStatus"
              class="el-def"
              placeholder="게시 상태"
              :reduce="option => option.code"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              등록 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refBannerTable"
        primary-key="id"
        :items="fetchBanners"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedBanner"
      >
        <template #cell(bannerLocation)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value === 1 ? "상단" : "하단" }}
          </div>
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            ~
            {{ data.item.endDate | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetBannerData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규 등록</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="saveBanner()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removeBanner()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="formRules">
        <b-row class="mt-1">
          <b-col
            cols="12"
          >
            <b-form-group
              label="배너 위치 선택"
              label-for="banner-location"
            >
              <validation-provider
                #default="{ errors }"
                name="배너 위치 선택"
                rules="required"
              >
                <b-form-radio-group
                  v-model="bannerData.bannerLocation"
                  :options="codes.bannerLocation"
                  value-field="code"
                  text-field="label"
                  @change="changeBannerLocation"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="bannerData.bannerLocation === 1">
          <b-col
            cols="12"
          >
            <b-form-group
              label="콘텐츠 유형 선택"
              label-for="banner-content-type"
            >
              <validation-provider
                #default="{ errors }"
                name="콘텐츠 유형 선택"
                rules="required"
              >
                <b-form-radio-group
                  v-model="bannerData.contentType"
                  :options="codes.topBannerContentType"
                  value-field="code"
                  text-field="label"
                  @change="changeContentType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="bannerData.bannerLocation === 2">
          <b-col
            cols="12"
          >
            <b-form-group
              label="콘텐츠 유형 선택"
              label-for="banner-content-type"
            >
              <validation-provider
                #default="{ errors }"
                name="콘텐츠 유형 선택"
                rules="required"
              >
                <b-form-radio-group
                  v-model="bannerData.contentType"
                  :options="codes.botBannerContentType"
                  value-field="code"
                  text-field="label"
                  @change="changeContentType"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="8"
            md="6"
          >
            <b-form-group
              label="콘텐츠 선택"
              label-for="banner-content-title"
            >
              <validation-provider
                #default="{ errors }"
                name="콘텐츠"
                rules="required"
              >
                <b-form-input
                  id="banner-content-title"
                  v-model="bannerData.title"
                  :state="errors.length > 0 ? false : null"
                  readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            md="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="openBannerContentSelect"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-start justify-content-center"
          >
            <b-form-group
              label="게시 상태"
              label-for="survey-allow-join-type"
            >
              <b-form-radio-group
                value-field="code"
                text-field="label"
              >
                <b-form-radio
                  v-for="item in codes.postStatus"
                  :key="item.code"
                  :value="item.code"
                  :checked="bannerData.postStatus"
                  :disabled="item.code !== bannerData.postStatus"
                >
                  {{ item.label }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="배너 등록 (※ 이미지 최적 사이즈 => 상단배너: 242 * 300 / 하단배너: 242 * 250)"
              label-for="banner-image-file"
            >
              <div>
                <b-card-text
                  class="my-50 text-primary"
                >
                  {{ bannerData.fileName }}
                </b-card-text>
              </div>
              <b-form-file
                v-model="bannerImage"
                accept="image/*"
                @input="readBannerImage"
              />
              <validation-provider
                #default="{ errors }"
                name="배너이미지"
                rules="required"
              >
                <b-form-input
                  v-show="false"
                  v-model="bannerData.fileName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label>게시 기간</label>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label=""
              label-for="banner-start-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="게시시작일"
                :rules="{
                  required: true,
                  before: bannerData.endDate,
                  dateBetween: [bannerData.contentStartDate, bannerData.contentEndDate],
                }"
              >
                <b-form-datepicker
                  id="banner-start-date"
                  v-model="bannerData.startDate"
                  placeholder="게시시작일"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '게시시작일은 콘텐츠 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label=""
              label-for="banner-end-date"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="게시종료일"
                :rules="{
                  required: true,
                  after: bannerData.startDate,
                  dateBetween: [bannerData.contentStartDate, bannerData.contentEndDate],
                }"
              >
                <b-form-datepicker
                  id="banner-end-date"
                  v-model="bannerData.endDate"
                  placeholder="게시종료일"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '게시종료일은 콘텐츠 게시기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <banner-content-select
      :content-type="bannerData.contentType"
      :show-banner-content-select="showBannerContentSelect"
      @close="bannerContentSelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { BCardText, BFormFile } from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { clone } from '@core/utils/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BannerContentSelect from './BannerContentSelect.vue'

export default {
  components: {
    vSelect,
    BCardText,
    BFormFile,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    BannerContentSelect,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const bannerInitState = {
      id: null,
      contentId: null,
      contentType: null,
      bannerLocation: 1, // 1: 상단배너
      startDate: null,
      endDate: null,
      title: null,
      postStatus: null,
      fileName: null,
      contentStartDate: null,
      contentEndDate: null,
    }
    const bannerData = ref({ ...bannerInitState })

    const resetBannerData = () => {
      Object.assign(bannerData.value, bannerInitState)
      bannerImage.value = null

      refs.formRules.reset()
    }

    const showBannerContentSelect = ref(false)
    const bannerContentSelect = {
      close: rs => {
        showBannerContentSelect.value = false
        if (rs) {
          bannerData.value.contentId = rs.id
          bannerData.value.title = rs.title

          if (rs.startDate && rs.endDate) {
            bannerData.value.startDate = dayjs(rs.startDate).format('YYYY-MM-DD')
            bannerData.value.endDate = dayjs(rs.endDate).format('YYYY-MM-DD')
            bannerData.value.contentStartDate = dayjs(rs.startDate).format('YYYY-MM-DD')
            bannerData.value.contentEndDate = dayjs(rs.endDate).format('YYYY-MM-DD')
          } else {
            bannerData.value.startDate = null
            bannerData.value.endDate = null
            bannerData.value.contentStartDate = null
            bannerData.value.contentEndDate = null
          }
        }
      },
    }

    // Set Codes
    const codes = ref({
      bannerContentType: [],
      topBannerContentType: [],
      botBannerContentType: [],
      bannerLocation: [],
      postStatus: [],
      use: [
        { label: '사용', code: true },
        { label: '사용안함', code: false },
      ],
    })

    const fetchCodes = () => {
      axios.get('/fa/banner/codes')
        .then(rs => {
          const {
            bannerContentType,
            topBannerContentType,
            botBannerContentType,
            bannerLocation,
            postStatusType,
          } = rs.data
          codes.value.bannerContentType = bannerContentType
          codes.value.topBannerContentType = topBannerContentType
          codes.value.botBannerContentType = botBannerContentType
          codes.value.bannerLocation = bannerLocation
          codes.value.postStatus = postStatusType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      location: null,
      contentType: null,
      postStatus: 1,
      startDate: null,
      endDate: null,
    })
    // Query Data End.

    // Main Table Config
    const refBannerTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'ID', sortable: true, thStyle: { width: '9%' },
      },
      {
        key: 'bannerLocation', label: '위치', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'contentTypeName', label: '구분', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'title', label: '제목', sortable: true,
      },
      {
        key: 'startDate', label: '게시 기간', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'postStatusName', label: '게시 상태', sortable: true, thStyle: { width: '10%' },
      },
    ]
    const refetchData = () => {
      refBannerTable.value.refresh()
    }
    const onRowSelectedBanner = items => {
      const item = items[0]
      if (item && item.id) {
        resetBannerData()

        const banner = clone(item)
        if (banner.contentStartDate && banner.contentEndDate) {
          banner.contentStartDate = dayjs(banner.contentStartDate).format('YYYY-MM-DD')
          banner.contentEndDate = dayjs(banner.contentEndDate).format('YYYY-MM-DD')
        }

        bannerData.value = banner
      }
    }
    // Main Table Config End.

    const openBannerContentSelect = () => {
      if (!bannerData.value.contentType) {
        pushToast('warning', '콘텐츠 유형을 선택하세요.')
        return
      }

      showBannerContentSelect.value = true
    }

    const changeBannerLocation = () => {
      if (bannerData.value.bannerLocation) {
        bannerData.value.contentType = null
        changeContentType()
      }
    }

    const changeContentType = () => {
      if (bannerData.value.contentId) {
        pushToast('warning', '콘텐츠 유형이 변경되어 선택 정보를 초기화합니다.')

        bannerData.value.contentId = null
        bannerData.value.title = null
        bannerData.value.startDate = null
        bannerData.value.endDate = null
        bannerData.value.contentStartDate = null
        bannerData.value.contentEndDate = null
      }
    }

    const bannerImage = ref(null)

    const readBannerImage = file => {
      if (!file) return
      if (file.type.indexOf('image/') === -1) {
        toast({
          component: ToastificationContent,
          props: {
            title: '잘못된 파일 형식입니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        bannerImage.value = null
        return
      }
      bannerImage.value = file
      bannerData.value.fileName = file.name
    }

    const fetchBanners = (ctx, callback) => {
      axios.post('/fa/banner/list', {
        search: {
          location: query.value.location,
          contentType: query.value.contentType,
          startDate: query.value.startDate ? `${query.value.startDate}T00:00:00` : null,
          endDate: query.value.endDate ? `${query.value.endDate}T23:59:59` : null,
          postStatus: query.value.postStatus,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const saveBanner = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return
        const { id } = bannerData.value
        if (id) {
          updateBanner()
        } else {
          createBanner()
        }
      })
    }

    const createBanner = () => {
      const formdata = new FormData()

      formdata.append("contentId", bannerData.value.contentId)
      formdata.append("title", bannerData.value.title)
      formdata.append("contentType", bannerData.value.contentType)
      formdata.append("bannerLocation", bannerData.value.bannerLocation)
      formdata.append("startDate", bannerData.value.startDate)
      formdata.append("endDate", bannerData.value.endDate)

      formdata.append("bannerImage", bannerImage.value)

      axios.post("/fa/banner", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          pushToast('success', '배너 등록 완료')
          refetchData()
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const updateBanner = () => {
      const formdata = new FormData()

      formdata.append("id", bannerData.value.id)
      formdata.append("contentId", bannerData.value.contentId)
      formdata.append("title", bannerData.value.title)
      formdata.append("contentType", bannerData.value.contentType)
      formdata.append("bannerLocation", bannerData.value.bannerLocation)
      formdata.append("startDate", bannerData.value.startDate)
      formdata.append("endDate", bannerData.value.endDate)

      if (bannerImage.value) formdata.append("bannerImage", bannerImage.value)

      axios.patch("/fa/banner", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          pushToast('success', '배너 저장 완료')
          refetchData()
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const removeBanner = () => {
      if (!bannerData.value.id) {
        pushToast('warning', '삭제할 대상을 선택하세요.')
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/banner/${bannerData.value.id}`)
            .then(() => {
              pushToast('success', '데이터 삭제 완료')

              refetchData()
              resetBannerData()
            })
            .catch(() => {
              pushToast('danger', '데이터를 삭제하는 과정에서 오류가 발생하였습니다.')
            })
        })
    }

    return {
      query,
      codes,
      bannerData,
      tableColumns,
      refBannerTable,
      sortBy,
      isSortDesc,
      required,
      refetchData,
      totalRecords,
      fetchBanners,
      resetBannerData,
      onRowSelectedBanner,
      saveBanner,
      removeBanner,
      readBannerImage,
      bannerImage,
      showBannerContentSelect,
      bannerContentSelect,
      openBannerContentSelect,
      changeContentType,
      changeBannerLocation,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
